// eslint-disable-next-line @typescript-eslint/no-unused-vars
import moment from 'moment-timezone';

export default class Formatters {
  movementTypeIcon(type) {
    let typeFormated = '';
    if (type !== null && typeof type !== 'undefined') {
      switch (type.toLowerCase()) {
        case 'insert':
        case 'insert_dependent':
          typeFormated = 'fas fa-file-import';
          break;
        case 'implantation':
        case 'implantation_dependent':
          typeFormated = 'fas fa-tools';
          break;
        case 'reactivation':
        case 'reactivation_dependent':
          typeFormated = 'fas fa-retweet';
          break;
        case 'transfer':
          typeFormated = 'fas fa-random';
          break;
        case 'update':
        case 'update_dependent':
          typeFormated = 'fas fa-sync-alt';
          break;
        case 'delete':
        case 'delete_dependent':
          typeFormated = 'fas fa-trash';
          break;
        default:
          typeFormated = type;
          break;
      }
    }
    return typeFormated;
  }

  movementTypeMessage(type, freeMovement) {
    let message = '';
    if (type !== null && typeof type !== 'undefined') {
      switch (type.toLowerCase()) {
        case 'insert':
        case 'insert_dependent':
          message = freeMovement ? 'Inserção (ML)' : 'Inserção';
          break;
        case 'implantation':
        case 'implantation_dependent':
          message = freeMovement ? 'Implantação (ML)' : 'Implantação';
          break;
        case 'reactivation':
        case 'reactivation_dependent':
          message = freeMovement ? 'Reativação (ML)' : 'Reativação';
          break;
        case 'transfer':
          message = freeMovement ? 'Transferência (ML)' : 'Transferência';
          break;
        case 'update':
        case 'update_dependent':
          message = freeMovement ? 'Atualização (ML)' : 'Atualização';
          break;
        case 'delete':
        case 'delete_dependent':
          message = freeMovement ? 'Exclusão (ML)' : 'Exclusão';
          break;
        default:
          message = type;
          break;
      }
    }
    return message;
  }

  formatStatusIcon(status) {
    let statusFormatted = '';
    switch (status) {
      case 'CRITICIZED':
      case 'NOT_ELIGIBLE':
      case 'INVALID':
        statusFormatted = 'fas fa-user-times';
        break;
      case 'TRANSFORMED':
      case 'WAIT_RULES_VALIDATION':
      case 'WAIT_MOVEMENT_TYPE_CLASSIFICATION':
        statusFormatted = 'fas fa-cogs';
        break;
      case 'VALID':
      case 'ELIGIBLE':
      case 'ADJUSTED':
        statusFormatted = 'fas fa-user-check';
        break;
      case 'SUBMITTED':
        statusFormatted = 'mdi-email-arrow-right';
        break;
      case 'SUCCESS':
        statusFormatted = 'fas fa-check';
        break;
      case 'INVALID_IN_CARRIER':
        statusFormatted = 'mdi-account-cancel';
        break;
      case 'WAITING_APPROVAL_RULES_VALIDATIONS':
        statusFormatted = 'fas fa-hourglass-half';
        break;
      case 'CANCELED':
        statusFormatted = 'fas fa-ban';
        break;
      case 'FINISH_WITH_CRITICAL':
        statusFormatted = 'fas fa-user-slash';
        break;
      case 'SCHEDULED':
        statusFormatted = 'mdi mdi-calendar-clock';
        break;
      case 'IN_REVIEW':
        statusFormatted = 'mdi mdi-file-search';
        break;
      case 'WAIT_SUBMISSION':
        statusFormatted = 'mdi mdi-package-check';
        break;
      default:
        statusFormatted = status;
        break;
    }
    return statusFormatted;
  }

  formatStatusMessage(status, beneficaryIntegrationStatus) {
    let message = '';
    switch (status) {
      case 'CRITICIZED':
      case 'INVALID':
        message = 'Criticado Cadastro';
        break;
      case 'NOT_ELIGIBLE':
        message = 'Não elegível';
        break;
      case 'VALID':
      case 'ELIGIBLE':
      case 'ADJUSTED':
        message = 'Liberada';
        break;
      case 'TRANSFORMED':
      case 'WAIT_RULES_VALIDATION':
      case 'WAIT_MOVEMENT_TYPE_CLASSIFICATION':
        message = 'Em processamento';
        break;
      case 'SUBMITTED':
        message = 'Enviado para operadora';
        break;
      case 'SUCCESS':
        if (beneficaryIntegrationStatus === 'INTEGRATION_COMPLETED') {
          message = 'Finalizado com sucesso';
        } else if (beneficaryIntegrationStatus !== 'INTEGRATION_COMPLETED' || beneficaryIntegrationStatus === null) {
          message = 'Finalizado na operadora';
        }
        break;
      case 'INVALID_IN_CARRIER':
        message = 'Criticado Operadora';
        break;
      case 'WAITING_APPROVAL_RULES_VALIDATIONS':
        message = 'Aguardando liberação';
        break;
      case 'CANCELED':
        message = 'Cancelado';
        break;
      case 'FINISH_WITH_CRITICAL':
        message = 'Finalizado com crítica';
        break;
      case 'SCHEDULED':
        message = 'Agendado';
        break;
      case 'IN_REVIEW':
        message = 'Em análise';
        break;
      case 'WITH_INTERNAL_CRITICISM':
        message = 'Com críticas internas';
        break;
      case 'WAIT_SUBMISSION':
        message = 'Separado para envio';
        break;
      case 'WAIT_SUPPORT':
        message = 'Aguardando atendimento';
        break;
      default:
        message = status;
        break;
    }
    return message;
  }

  batchStatusFormatter(status) {
    let statusFormatted = '';
    switch (status.toUpperCase()) {
      case 'WAIT_TRANSFORMATION':
      case 'TRANSFORMATION_IN_PROGRESS':
      case 'WAIT_CONVERSION':
      case 'CONVERSION_IN_PROGRESS':
      case 'WAIT_SEND_API':
        statusFormatted = 'Em processamento';
        break;
      case 'FILE_GENERATED':
        statusFormatted = 'Aguardando envio';
        break;
      case 'SUBMITTED':
      case 'PROCESSING_RETURN':
      case 'CARRIER_RETURN_PROCESSED':
        statusFormatted = 'Enviado';
        break;
      case 'API_PARTIAL_SUBMITTED':
        statusFormatted = 'Enviado Parcialmente';
        break;
      case 'CANCELED':
        statusFormatted = 'Cancelado';
        break;
      case 'TRANSFORMATION_ERROR':
      case 'CONVERSION_ERROR':
        statusFormatted = 'Erro ao gerar arquivo';
        break;
      case 'API_ERROR':
        statusFormatted = 'Erro na API';
        break;
      case 'WAIT_SEND_FILE':
        statusFormatted = 'Finalizando Lote';
        break;
      case 'API_INVALID_IN_CARRIER':
        statusFormatted = 'Criticado na API';
        break;
      case 'SCHEDULED':
        statusFormatted = 'Agendado';
        break;
      default:
        statusFormatted = 'Status inexistente';
        break;
    }
    return statusFormatted;
  }

  formatBeneficiary(type) {
    let message = '';
    switch (type) {
      case 'DEPENDENT':
        message = 'Dependente';
        break;
      case 'HOLDER':
        message = 'Titular';
        break;
      case 'AGREGATE':
        message = 'Agregado';
        break;
      default:
        message = type;
        break;
    }
    return message;
  }

  formatCpf(cpf) {
    return cpf ? cpf.replace(/\D/g, '').replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, '$1.$2.$3-$4') : '';
  }

  formatCnpj(value) {
    return value ? value.replace(/[^\d]+/g, '') : '';
  }

  formatDnv(dnv) {
    return dnv ? dnv.replace(/\D/g, '').replace(/^(\d{2})(\d{8})?(\d{1})?/, '$1-$2-$3') : '';
  }

  formatFinancialGroup(number, financialGroups) {
    if (number && financialGroups) {
      // eslint-disable-next-line radix
      const id = parseInt(number);
      if (typeof id === 'number') {
        const group = financialGroups.filter((item) => item.id === id);
        if (group[0]) {
          return (group[0].name);
        }
      }
    }
    return '-';
  }

  formatFinancialGroups(numbers, financialGroups) {
    if (numbers && financialGroups) {
      let listFinancialGroupNames = [];
      numbers.forEach((element) => {
        listFinancialGroupNames.push(this.formatFinancialGroup(element, financialGroups));
      });

      if(numbers.length > 0) {
        return [listFinancialGroupNames.slice(0, -1).join(', '), listFinancialGroupNames.slice(-1)[0]].join(listFinancialGroupNames.length < 2 ? '' : ' e ');
      }      
    }
    return '-';
  }

  formatContract(number, contracts) {
    if (number && contracts) {
      // eslint-disable-next-line radix
      const id = parseInt(number);
      if (typeof id === 'number') {
        const group = contracts.filter((item) => item.id === id);
        if (group[0]) {
          return (group[0].name);
        }
      }
    }
    return '-';
  }

  formatDate(date) {
    if (!date) return null;
    try {
      return Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(new Date(date));
    } catch (e) {
      return date;
    }
  }

  formatDateTimeZone(date) {
    if (date) {
      return moment.utc(date).tz('America/Sao_Paulo').format('DD/MM/YYYY');
    }
    return null;
  }

  formatDateTimeZoneWithHours(date) {
    if (date) {
      return moment.utc(date).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm');
    }
    return null;
  }

  formatDateBRtoString(receivingDateFormatted) {
    if (receivingDateFormatted && receivingDateFormatted.length === 10) {
      const day = receivingDateFormatted.split('/')[0];
      const month = receivingDateFormatted.split('/')[1];
      const year = receivingDateFormatted.split('/')[2];

      return `${year}-${`0${month}`.slice(-2)}-${`0${day}`.slice(-2)}`;
    }
    return '';
  }

  removeNonDigit(value) {
    if (value) {
      return value.replace(/\D/g, '');
    }
    return value;
  }

  formatMaritalStatus(id, maritalStatus) {
    if (id && maritalStatus) {
      const status = maritalStatus.find((item) => item.id === id);
      return status.description;
    }
    return null;
  }

  formatGender(id, genders) {
    if (id && genders) {
      const gender = genders.find((item) => item.id === id);
      return gender.description;
    }
    return null;
  }

  formatKinship(id, kinships) {
    if (id && kinships && kinships.length > 0) {
      const kinship = kinships.find((item) => item.id === id);
      return kinship.description;
    }
    return null;
  }

  formatToBRLCurrency(value) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }

  convertCurrencyStringToFloat(moneyStr) {
    let valueConvert = moneyStr.replaceAll('.', '');
    valueConvert = valueConvert.replace(',', '.');
    const decimalValue = parseFloat(valueConvert);
    return decimalValue.toFixed(2);
  }

  formatComissionExtractStatus(type) {
    let message = '';
    switch (type) {
      case 'FINISH':
        message = 'Finalizado';
        break;
      case 'EXTRACT_ERROR':
        message = 'Erro de Extração';
        break;
      case 'WAIT_EXTRACT':
        message = 'Aguardando Extração';
        break;
      case 'WAIT_TRANSFORMATION':
        message = 'Aguardando Transformação';
        break;
      case 'TRANSFORMATION_IN_PROGRESS':
        message = 'Transformação em Progresso';
        break;
      case 'TRANSFORMATION_ERROR':
        message = 'Erro de Transformação';
        break;
      case 'EXTRACT_IN_PROGRESS':
        message = 'Extração em Progresso';
        break;
      case 'CANCELED':
        message = 'Cancelado';
        break;
      case 'COMPANY_MISSING':
        message = 'CNPJ Divergente';
        break;
      case 'DUPLICATED':
        message = 'Duplicado';
        break;
      case 'DIVERGENT_REVENUE':
        message = 'Valores Divergentes';
        break;
      case 'BENEFIT_MISSING':
        message = 'Beneficio Não Encontrado';
        break;
      case 'TAX_MISSING':
        message = 'Taxa Não Encontrada';
        break;
      case 'CARRIER_MISSING':
        message = 'CNPJ Não Encontrado';
        break;
      case 'PENDING_CONTRACT':
        message = 'Pedente Contratos';
        break;
      case 'PENDING_SALE_ORDER':
        message = 'Pedente Criação do Pedido de Venda';
        break;
      default:
        message = type;
        break;
    }
    return message;
  }

  formatBank(code, banks) {
    if (code && banks) {
      const bank = banks.find((item) => item.code === Number(code));
      return bank.code;
    }
    return null;
  }

  removeInvalidNumber(value) {
    let number;
    if (value) {
      // eslint-disable-next-line radix
      number = parseInt(value);
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(number)) {
        number = '';
      }
    }
    return number;
  }

  formatToTitleCase(str) {
    if (str) {
      let text = str;
      text = text.toLowerCase();

      text = text.split(' ');
      for (let i = 0; i < text.length; i += 1) {
        text[i] = text[i].charAt(0).toUpperCase() + text[i].slice(1);
      }
      return String(text.join(' '));
    }

    return null;
  }

  maskLimiter(number) {
    const hash = '#';
    return hash.repeat(number);
  }

  unmaskDnv(dnv) {
    return dnv.replaceAll('-', '');
  }

  statusFormatterFileMovements(status) {
    let statusFormatted = '-';
    switch (status.toLowerCase()) {
      case 'canceled':
        statusFormatted = 'Cancelado';
        break;
      case 'invalid':
        statusFormatted = 'Inválido';
        break;
      case 'collected':
        statusFormatted = 'Aguardando processamento';
        break;
      case 'converted':
        statusFormatted = 'Em processamento';
        break;
      case 'finished':
        statusFormatted = 'Processado';
        break;
      case 'partial':
        statusFormatted = 'Processado parcialmente';
        break;
      case 'refused':
        statusFormatted = 'Recusado';
        break;
      case 'cancellation_requested':
        statusFormatted = 'Cancelamento Solicitado';
        break;
      default:
        statusFormatted = 'Aguardando processamento';
        break;
    }
    return statusFormatted;
  }

  getPluralText(text, list) {
    const listLength = (list && list.length) ? list.length - 1 : 0;

    if (listLength <= 0) {
      return '';
    }

    const suffix = listLength === 1 ? '' : 's';
    return `(+ ${listLength} ${text}${suffix})`;
  }

  sortByProperty(array, property) {
    return array.sort((a, b) => a[property].localeCompare(b[property]));
  }

  removeAccents(text) {
    if(text) {
      text = text.toUpperCase();
      text = text.replace(new RegExp('[ÁÀÂÃ]','gi'), 'A');
      text = text.replace(new RegExp('[ÉÈÊ]','gi'), 'E');
      text = text.replace(new RegExp('[ÍÌÎ]','gi'), 'I');
      text = text.replace(new RegExp('[ÓÒÔÕ]','gi'), 'O');
      text = text.replace(new RegExp('[ÚÙÛ]','gi'), 'U');
      text = text.replace(new RegExp('[Ç]','gi'), 'C');
    }

    return text;
  }

  validateEmptyValues(data) {
    if (Array.isArray(data)) {
      data.forEach((obj) => {
        this.replaceEmptyValues(obj);
      });
    } else {
      this.replaceEmptyValues(data);
    }
    return data;
  }

  replaceEmptyValues(obj) {
    if (this.isValidObject(obj)) {
      if (Array.isArray(obj)) {
        obj.forEach((item) => this.processObject(item));
      }

      if (obj !== null && typeof obj === 'object' && !Array.isArray(obj)) {
        this.processObject(obj);
      }
    }
  }

  isValidObject(obj) {
    return Boolean(obj) && Object.entries(obj).length > 0;
  }

  processObject(obj) {
    Object.keys(obj).forEach((key) => {
      switch (true) {
        case (Array.isArray(obj[key])):
          obj[key].forEach((item) => this.processObject(item));
          break;

        case (obj[key] !== null && typeof obj[key] === 'object' && !Array.isArray(obj[key])):
          this.processObject(obj[key]);
          break;

        default:
          if (this.isEmptyValue(obj[key])) {
            obj[key] = null;
          }
          break;
      }
    });
  }

  isEmptyValue(value) {
    return value === undefined || (typeof value === 'string' && value.trim() === '');
  }
}
