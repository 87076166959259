import {
  VuexModule, Module, getModule, MutationAction, Mutation, Action,
} from 'vuex-module-decorators';
import store from '@/store/index';
import Vue from 'vue';
import router from '@/router';
import { OperationalRoutinesModel } from './operational-routines-types';

@Module({
  namespaced: true, name: 'operational-routines', dynamic: true, store,
})
class OperationalRoutinesStore extends VuexModule {
  data: OperationalRoutinesModel = new OperationalRoutinesModel()

  get invoiceValidations() {
    return this.data.carrierInvoiceValidations;
  }

  get invoicePriceValidations() {
    return this.data.carrierInvoicePriceValidations;
  }

  get invoiceValidationDetail() {
    return this.data.carrierInvoiceValidationDetail;
  }

  get details() {
    return this.data.validationDetails;
  }

  get detailCriticism() {
    return this.data.validationDetailCriticism;
  }

  get detailComment() {
    return this.data.validationDetailComment;
  }

  get comment() {
    return this.data.validationDetailComment;
  }

  get invoiceTypes() {
    return this.data.carrierInvoiceTypes;
  }

  get contractSelect() {
    return this.data.contractSelected;
  }

  get cardValidationId() {
    return this.data.cardValidationId;
  }

  @Mutation
  setCarrierInvoiceValidations(payload: []) {
    payload.map((validation: any) => {
      const successRegistryDetailIds = Boolean(validation.successRegistryDetailIds)
        ? validation.successRegistryDetailIds.split(';').map((value: any) => parseInt(value)) : [];
      const errorRegistryDetailIds = Boolean(validation.errorRegistryDetailIds)
        ? validation.errorRegistryDetailIds.split(';').map((value: any) => parseInt(value)) : [];
      const errorRegistryDetailIdsChecked = Boolean(validation.errorRegistryDetailIdsChecked)
        ? validation.errorRegistryDetailIdsChecked.split(';').map((value: any) => parseInt(value)) : [];
      const successValueDetailIds = Boolean(validation.successValueDetailIds)
        ? validation.successValueDetailIds.split(';').map((value: any) => parseInt(value)) : [];
      const errorValueDetailIds = Boolean(validation.errorValueDetailIds)
        ? validation.errorValueDetailIds.split(';').map((value: any) => parseInt(value)) : [];
      const errorValueDetailIdsChecked = Boolean(validation.errorValueDetailIdsChecked)
        ? validation.errorValueDetailIdsChecked.split(';').map((value: any) => parseInt(value)) : [];
      const notFoundInvoiceDetailIds = Boolean(validation.notFoundInvoiceDetailIds)
        ? validation.notFoundInvoiceDetailIds.split(';').map((value: any) => parseInt(value)) : [];
      const notFoundPreviewDetailIds = Boolean(validation.notFoundPreviewDetailIds)
        ? validation.notFoundPreviewDetailIds.split(';').map((value: any) => parseInt(value)) : [];
      const notFoundInvoiceDetailIdsChecked = Boolean(validation.notFoundInvoiceDetailIdsChecked)
        ? validation.notFoundInvoiceDetailIdsChecked.split(';').map((value: any) => parseInt(value)) : [];
      const notFoundPreviewDetailIdsChecked = Boolean(validation.notFoundPreviewDetailIdsChecked)
        ? validation.notFoundPreviewDetailIdsChecked.split(';').map((value: any) => parseInt(value)) : [];

      validation.successRegistryDetailIds = successRegistryDetailIds;
      validation.errorRegistryDetailIds = errorRegistryDetailIds;
      validation.errorRegistryDetailIdsChecked = errorRegistryDetailIdsChecked;
      validation.successValueDetailIds = successValueDetailIds;
      validation.errorValueDetailIds = errorValueDetailIds;
      validation.errorValueDetailIdsChecked = errorValueDetailIdsChecked;
      validation.notFoundInvoiceDetailIds = notFoundInvoiceDetailIds;
      validation.notFoundPreviewDetailIds = notFoundPreviewDetailIds;
      validation.notFoundInvoiceDetailIdsChecked = notFoundInvoiceDetailIdsChecked;
      validation.notFoundPreviewDetailIdsChecked = notFoundPreviewDetailIdsChecked;
    })
    this.data.carrierInvoiceValidations = payload;
  }

  @Mutation
  setCarrierInvoicePriceValidations(payload: []) {
    this.data.carrierInvoicePriceValidations = payload;
  }

  @Mutation
  setCarrierInvoiceValidationDetail(payload: []) {
    this.data.carrierInvoiceValidationDetail = payload;
  }

  @Mutation
  setValidationDetails(payload: []) {
    this.data.validationDetails = payload;
  }

  @Mutation
  setValidationDetailCriticism(payload: []) {
    this.data.validationDetailCriticism = payload;
  }

  @Mutation
  setValidationDetailComment(payload: []) {
    this.data.validationDetailComment = payload;
  }

  @Mutation
  setValidationComment(payload: []) {
    this.data.validationComment = payload;
  }

  @Mutation
  setCarrierInvoiceTypes(payload: []) {
    this.data.carrierInvoiceTypes = payload;
  }

  @Mutation
  setContractSelected(contract: any) {
    this.data.contractSelected = contract;
  }

  @Mutation
  updateInvoiceValidation(validationId: number) {
    if (!this.data.carrierInvoicePriceValidations || !Array.isArray(this.data.carrierInvoicePriceValidations)) return;

    const invoiceIndex = this.data.carrierInvoicePriceValidations.findIndex(invoice => invoice.validationId === validationId);
    if (invoiceIndex === -1) return;

    this.data.carrierInvoicePriceValidations[invoiceIndex].checked = true;
  }

  @Mutation
  updateInvoiceValidationDetail(validationDetailId: number) {
    if (!this.data.carrierInvoiceValidationDetail || !Array.isArray(this.data.carrierInvoiceValidationDetail)) return;

    const invoiceIndex = this.data.carrierInvoiceValidationDetail.findIndex(invoice => invoice.validationDetailId === validationDetailId);
    if (invoiceIndex === -1) return;

    this.data.carrierInvoiceValidationDetail[invoiceIndex].checked = true;
  }

  @Mutation
  updateInvoice(invoiceSelected: any) {
    if (!this.data.carrierInvoicePriceValidations || !Array.isArray(this.data.carrierInvoicePriceValidations)) return;

    const invoiceIndex = this.data.carrierInvoicePriceValidations.findIndex(invoice => invoice.validationId === invoiceSelected.validationId);
    if (invoiceIndex === -1) return;

    this.data.carrierInvoicePriceValidations[invoiceIndex].previewValues = invoiceSelected.previewValues;
  }

  @Mutation
  setCardValidationId(payload: number | null | undefined) {
    this.data.cardValidationId = payload ?? null;
  }

  @Mutation
  resetModule() {
    this.data = new OperationalRoutinesModel();
  }
}

export default getModule(OperationalRoutinesStore);
