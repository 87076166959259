class InvoiceValues {
  PM?: number
  ISS?: number
  DR?: number
  IOF?: number
}

class CarrierInvoiceValidation {
  validationId?: number
  competencyDate?: string
  carrier?: object
  financialGroup?: object
  subContractId?: string
  policy?: string
  benefitType?: string
  name?: string
  companyDocument?: string
  companyName?: string
  countRegistrySuccess?: number
  countValueSuccess?: number
  countRegistryError?: number
  countValueError?: number
  countRegistryNotFoundInvoice?: number
  countRegistryNotFoundPreview?: number
}

class CarrierInvoicePriceValidation {
  validationId?: number
  carrierInvoiceId?: number
  carrierInvoicePreviewId?: number
  competencyDate?: string
  carrier?: object
  financialGroup?: object
  subContractId?: string
  policy?: string
  benefitType?: string
  name?: string
  companyDocument?: string
  companyName?: string
  status?: string
  checked?: boolean
  invoiceTotalLives?: number
  invoiceTotalValue?: number
  previewTotalLives?: number
  previewTotalValue?: number
  invoiceValues?: InvoiceValues
  previewValues?: InvoiceValues
}

class CarrierInvoiceValidationDetail {
  validationId?: number
  validationDetailId?: number
  invoiceId?: number
  previewId?: number
  status?: string
  checked?: boolean
  carrier?: object
  financialGroup?: object
  subContractId?: string
  policy?: string
  companyDocument?: string
  companyName?: string
  competencyDate?: string
  invoiceDate?: string
  invoiceType?: string
  beneficiaryName?: string
  beneficiaryDocumentNumber?: string
  beneficiaryType?: string
  birthDate?: string
  familyCode?: string
  familyCodeSeq?: string
  planCode?: string
  planPrice?: string
}

class ValidationDetail {
  id?: number
  carrierInvoiceId?: number
  competencyDate?: string
  invoiceDate?: string
  invoiceType?: string
  insuredName?: string
  insuredDocumentNumber?: string
  premiumValue?: number
  benefitCode?: string
  planCode?: string
  beneficiaryType?: string
  birthDate?: string
  kinship?: string
  cardNumber?: string
  familyCode?: string
  familyCodeSeq?: string
  inclusionDate?: string
  exclusionDate?: any
  financialGroupId?: string
  carrierId?: string
  contractId?: string
  subContractId?: string
  subContractNumber?: string
  cnpj?: string
  createdAt?: string
  updatedAt?: any
}

export class OperationalRoutinesModel {
  carrierInvoiceValidations?: CarrierInvoiceValidation[];
  carrierInvoicePriceValidations?: CarrierInvoicePriceValidation[];
  carrierInvoiceValidationDetail?: CarrierInvoiceValidationDetail[];
  validationDetails?: ValidationDetail[];
  validationDetailCriticism?: string[];
  validationDetailComment?: string[];
  validationComment?: string[];
  carrierInvoiceTypes?: string[];
  contractSelected?: CarrierInvoiceValidation;
  cardValidationId?: number | null | undefined = null;

  constructor() {
    this.carrierInvoiceValidations = [];
    this.carrierInvoicePriceValidations = [];
    this.carrierInvoiceValidationDetail = [];
    this.validationDetails = [];
    this.validationDetailCriticism = [];
    this.validationDetailComment = [];
    this.validationComment = [];
    this.carrierInvoiceTypes = [];
    this.contractSelected = {};
    this.cardValidationId = null;
  }
}