const USER_SESSION_STORAGE_KEY = '@auth/user';
const ENCODE_KEY = 'base64';
const DECODE_KEY = 'ascii';

export default class UserUtils {
  createUserSessionStorage(userSessionStorage) {
    if (userSessionStorage) {
      // eslint-disable-next-line max-len
      sessionStorage.setItem(USER_SESSION_STORAGE_KEY, Buffer.from(JSON.stringify(userSessionStorage)).toString(ENCODE_KEY));
    }
  }

  getUserSessionStorage() {
    if (!sessionStorage.getItem(USER_SESSION_STORAGE_KEY)) {
      return null;
    }
    // eslint-disable-next-line max-len
    return JSON.parse(Buffer.from(sessionStorage.getItem(USER_SESSION_STORAGE_KEY), ENCODE_KEY).toString(DECODE_KEY));
  }

  hasAuthorityToAccess(meta) {
    const userSessionStorage = this.getUserSessionStorage();
    // eslint-disable-next-line max-len
    if (meta && meta.authority) {
      // eslint-disable-next-line max-len
      if (!userSessionStorage || !userSessionStorage.authorities || !this.hasAuthority(userSessionStorage.authorities, meta.authority)) {
        return false;
      }
    }
    return true;
  }

  hasAuthority(authorities, authority) {
    return authorities.some((element) => element.authority === authority);
  }
}
